@import "~normalize.css/normalize";

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

@import "~tether-drop/dist/css/drop-theme-arrows-bounce";

@import "~ng-dialog/css/ngDialog";
@import "~ng-dialog/css/ngDialog-theme-default";

@import "~angular-ladda-lw/dist/angular-ladda-lw";

@import "~angular/angular-csp";

@import "core/variables";
@import "core/zindex";
@import "core/fp";

@import "core/angular-resizable";
@import "core/button";
@import "core/form";
@import "core/drop";
@import "core/tooltip";
@import "core/modal";
@import "core/message";
@import "core/_ribbon";
@import "core/dropdown";
@import "core/autosuggest";
@import "core/calendar";
@import "core/alert";
@import "core/collapse";
@import "core/lato";
@import "core/help";

html,
body,
.full-height {
  height: 100%;
}

.no-overflow {
  overflow: hidden;
}

html {
  box-sizing: border-box;
  line-height: 1.5;
  // overflow: hidden; // stop body overscroll
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  // $tablet: new-breakpoint(max-width 768px 8);
  // @include media($tablet) {
  //   font-size: 0.8em;
  // }

  -webkit-font-smoothing: subpixel-antialiased;

  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

a {
  color: inherit;
}

input,
button {
  font-family: inherit;
}

button {
  &:not(:disabled) {
    cursor: pointer;
  }
}

// For mobile safari
input {
  border-radius: 0;
}

::-ms-clear {
  display: none;
}

@import "e404";
@import "signup";

@import "viewTransition";
@import "budgets";
@import "budgetsHeader";
@import "budgetsFooter";
@import "appView";
@import "monthOverview";
@import "monthBody";
@import "budget";
@import "account";
@import "monthSelect";
@import "serviceWorkerStatus";
@import "syncStatus";
@import "flag";
@import "cleared";
@import "virtualList";
@import "reportSelect";
@import "reportSub";
@import "report";
@import "overspendingSelect";
@import "creditCard";
@import "verifyEmail";
@import "resetPassword";
@import "reconcileAccount";
@import "editAccount";
@import "mobileTransaction";
@import "mobile/mobileKeypad";
@import "mobile/mobileType";
@import "heatMap";
@import "outflowTip";
