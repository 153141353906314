// Put all z indices here, namespaced with 'z'

// Budget screen
$z-month-cell-input: 2;
$z-month-cell-input-focus: 3;

$z-month-cell-note: 4;

// Account screen
$z-editing-buttons: 10;

// Drop.js
$z-drop: 10001; // above modals

// app-view__title
$z-title: 2;
