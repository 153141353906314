.cleared {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.3rem;
  margin-top: 0.25rem;
  text-align: center;
  border-radius: 6px;

  background-color: transparentize($light-gray, 0.3);
  color: $white;

  cursor: pointer;

  transition: all 150ms ease;
}

.cleared--true {
  background-color: $primary;
}
