// http://www.color-hex.com/color-palette/1147

$primary: #76b852;
$primary-hover: #96ead7;

$light-primary: #87d25d;
$dark-primary: #5b903f;

$primary-alt: #00c6ff;

$blue-hover: #008bb3;

$blue-background: #00394a;

$warning: #f7e7b4;
$danger: #f00;
$white: #fff;

$form-border-inactive: #ddd;

$cell-header: #fbf5e1;

$dark-gray: #3e3e3e;
$light-gray: #ddd;

$positive-number: #90ee90;
$negative-number: #ff4c4c;

$tablet: "screen and (max-width 768px 8)";
$mobile: "screen and (max-width 480px 4)";
