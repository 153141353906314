.report {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.report__chart-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  canvas {
    position: absolute;
  }
}

.report__chart {
  flex: 1;
  padding: 1em;
}
