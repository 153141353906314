.reconcile-account {
  text-align: center;
  width: 300px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reconcile-account__balance-value {
  font-size: 1.65em;
}

.reconcile-account__center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reconcile-account__buttons {
  button:not(:first-child) {
    margin-left: 0.5em;
  }
}

.reconcile-account__all-done {
  font-size: 2em;
  margin: 0;
  color: white;
  font-weight: 300;
}

.reconcile-account__successful-reconcile {
  font-size: 1em;
  margin: 0;
  color: white;
  font-weight: 300;
  line-height: 1.3;
}

i.reconcile-account__done-icon {
  font-size: 3em;
  color: white;
}

.reconcile-account__input {
  outline: 0;
  padding: 5px;
  border: 1px solid $form-border-inactive;
  text-align: center;

  &:focus {
    border-color: $primary;
  }
}
