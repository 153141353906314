.verify-email {
  background-color: $primary;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  text-align: center;
}

.verify-email__text--loading {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.verify-email__text {
  font-size: 2em;
}

.verify-email__subtext {
  font-size: 1.25em;
  margin-bottom: 1em;
}

.verify-email__text,
.verify-email__subtext,
.verify-email__button {
  font-family: "lato", sans-serif;
  font-weight: 300;
}

$opaque: rgba(255, 255, 255, 0.2);

.verify-email__button {
  text-align: center;
  padding: 0.5em 1em;
  display: inline-block;
  text-decoration: none;
  font-size: 1.5em;
  background-color: $opaque;
  border: 0;
  color: $white;
}
