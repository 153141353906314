.autosuggest {
  position: relative;
  width: 100%;
  display: block;
  margin-top: 4px; // hack
}

.autosuggest__input {
  position: absolute;
  width: 100%;
  border: 0;

  min-width: auto;

  &:focus {
    outline: 0;
  }
}

.autosuggest__text {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 3px;
  overflow: hidden;
  line-height: 1;

  opacity: 0.3;
  color: black;
}
