.mobile-transaction {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
}

.mobile-transaction__amount {
  color: $positive-number;
  font-size: 4em;
  background: $blue-background;
  font-weight: 200;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-transaction__amount--negative {
  color: lighten($negative-number, 5%);
}

.mobile-transaction__detail {
  flex: 4;

  display: flex;
  flex-direction: column;
}
