.drop__content {
  font-size: 0.95rem;
  padding: 0.5em 1em;
}

.drop__content--wide {
  min-width: 300px;
}

.drop__content--small {
  font-size: 0.8rem;
}

.drop-element.drop--wide {
  min-width: 200px;
}

.drop-element {
  z-index: $z-drop;
}

.drop-element.drop-theme-arrows-bounce .drop-content {
  padding: 0.5em 0;

  box-shadow: 0 0 38px rgba(0, 0, 0, 0.2);

  // -webkit-filter: none;
  // -moz-filter: none;
}

.drop-element.drop-theme-arrows-bounce.drop-element-attached-top.drop-element-attached-center
  .drop-content::before {
  pointer-events: none;
}

.drop-theme--success {
  &.drop-element.drop-theme-arrows-bounce.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom
    .drop-content {
    animation: fade-to-primary 250ms ease;
    animation-fill-mode: forwards;
  }

  &.drop-element.drop-theme-arrows-bounce.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom
    .drop-content::before {
    animation: fade-to-primary-caret 250ms ease;
    animation-fill-mode: forwards;
  }
}

@keyframes fade-to-primary {
  from {
    background: white;
  }

  to {
    background: $primary;
  }
}

@keyframes fade-to-primary-caret {
  from {
    border-bottom-color: white;
  }

  to {
    border-bottom-color: $primary;
  }
}
