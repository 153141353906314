.heat-map {
  max-width: 900px;
  margin: 5px auto;
  display: block;

  text {
    font-size: 10px;
    font-weight: 300;
    fill: #aaa;
    text-transform: capitalize;
  }

  rect {
    &.active {
      stroke: #555;
      stroke-width: 1px;
    }
  }
}
