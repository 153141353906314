.report-sub {
  background-color: #eee;
  height: 2em;
  width: 100%;
  padding: 0 1em;

  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.report-sub__input {
  font-size: 0.9em;
  display: inline-block;

  &:hover {
    &,
    select {
      cursor: pointer;
      color: #555;
    }
  }

  &::after {
    font-family: "FontAwesome";
    content: "\f0d7";
  }

  select {
    appearance: none;
    border: 0;
    background: none;
    outline: 0;
  }
}
