$break-width: 900px;

.account {
  // -moz-box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  font-size: 0.9em;
  box-sizing: border-box;
  display: flex;
}

.account * {
  // -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.account__flag {
  @include ribbon(12px);
  margin-top: 9px;
  color: #ccc;
  cursor: pointer;
}

.account__header-flag {
  @include ribbon(12px);
  margin-top: 4px;

  transform: scale(0.8);

  color: $white;
}

.account__thead {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  text-transform: uppercase;
}

.account__tbody {
  display: inline-block;
}

.account__tbody--reconcile {
  overflow: hidden;
}

.account__tr--stripe {
  background: lighten($primary, 43%);
}

// .account__tbody--striped {
//   background: repeating-linear-gradient(
//     to bottom,
//     $white,
//     $white 30px,
//     lighten($primary, 43%) 30px,
//     lighten($primary, 43%) 60px
//   );
// }

.account__thead .account__tr,
.account__tbody .account__tr,
.account__tfoot .account__tr {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}

.account__th {
  height: 2em;
  background-color: $primary;

  color: $white;

  font-size: 0.75em;

  &[st-multi-sort] {
    display: flex;
    cursor: pointer;
  }

  span {
    flex: 1;

    line-height: 2em;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.st-sort-descent {
    &::after {
      content: "\f107";
    }
  }

  &.st-sort-ascent {
    &::after {
      margin-top: -1px;
      content: "\f106";
    }
  }

  &::after {
    font-family: "FontAwesome";
    font-size: 1.5em;
    line-height: 1.25em;
    vertical-align: middle;
  }

  &:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.account__th,
.account__td {
  display: inline-block;

  width: 20%; // this can vary
  // Small widths keeps consistent sizing
  min-width: 0;

  padding: 0 10px;
  font-weight: 400;

  input {
    &[type="text"] {
      width: calc(100% + 10px);
      height: 22px;
      margin-left: -5px;
      padding: 0 4px;
      border: 1px solid transparent;
      outline: 0;
      line-height: 1;

      &:focus {
        border-color: $primary-alt;
      }

      &:disabled {
        background-color: #ddd;
      }

      &.ng-invalid {
        border-color: $danger;
      }
    }
  }

  &,
  > span {
    text-overflow: ellipsis;
  }

  > span {
    overflow: hidden;
    display: flex;
    width: 100%;

    > span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.account__tfoot {
  display: inline-block;
}

.account__tfoot .account__td {
  width: 100%;
  display: inline-block;
}

.account__tbody .account__tr {
  &.st-selected {
    .account__td {
      background-color: $blue-hover;
      color: $white;
    }
  }

  &.account__tr--editing {
    background-color: $blue-hover;
    color: $white;
  }
}

.account__tr--overview {
  background-color: $dark-gray;

  display: flex;

  line-height: 3rem;

  @media (max-width: #{$break-width}) {
    flex-wrap: wrap;
  }
}

.account__tr--reconcile {
  background-color: $blue-hover;
  background: linear-gradient(135deg, $blue-hover, lighten($blue-hover, 10%));
  color: white;

  padding: 0.5em 1em;

  display: flex;
}

i.account__reconcile-close {
  line-height: 1.3em;
  font-size: 1.1em;
  margin-right: 1em;
  cursor: pointer;
}

.account__reconcile-button {
  margin-left: auto;

  background: none;
  border: 1px solid white;
  color: white;
  font-size: 0.8em;
  opacity: 0.8;

  transition: 100ms ease opacity;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.account__name {
  font-size: 1.4em;
  padding: 0 1em;
  font-weight: 300;
  color: $white;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account__name--all-accounts {
  @media (max-width: #{$break-width}) {
    width: 100%;
    text-align: center;
  }
}

.account__stats {
  display: flex;
  justify-content: center;

  @media (max-width: #{$break-width}) {
    order: 3;
    flex: 1 1 100%; // "1 100%" for safari
  }

  > div {
    display: flex;
  }
}

.account__balance,
.account__reconcile {
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account__balance {
  margin: 0 1em;
}

.account__balance-title {
  color: $white;
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 3px;

  white-space: nowrap;
}

.account__balance-value {
  color: $primary;
  font-size: 1.3em;
}

.account__balance-value--negative {
  color: $negative-number;
}

.account__operator {
  color: rgba(255, 255, 255, 0.3);
  font-size: 1.5em;
}

.account__reconcile {
  margin-left: auto;
  margin-right: 2em;

  button {
    border: 1px solid white;
    color: white;
    background: none;
    padding: 3px 7px;
    line-height: 15px;

    opacity: 0.5;

    transition: 100ms ease opacity;

    &:hover,
    &:focus,
    &.drop-enabled {
      opacity: 1;
    }
  }
}

.account__td {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.9em;
  line-height: 30px;
  height: 30px;

  // Prevent shift from selecting text
  user-select: none;
}

.account__cell-memo {
  flex-grow: 1;
  position: relative;
}

.account__cell-cleared {
  text-align: center;
  width: 6.5rem;
}

.account__cell-outflow,
.account__cell-inflow {
  width: 10rem;
}

.account__th--flag,
.account__td--flag {
  flex-shrink: 0;
  width: 40px;
}

.account__th--check,
.account__td--check {
  flex-shrink: 0;
  width: 75px;
}

.account__th--date,
.account__td--date {
  width: 120px;
}

.account__th--select,
.account__td--select {
  line-height: 1.9;

  width: 48px;
  padding: 0;
  text-align: center;

  input[type="checkbox"] {
    // for IE
    vertical-align: middle;
  }
}

.account__td--large {
  max-width: 170px;
}

.account__td--small {
  max-width: 100px;
}

.account__td--payee,
.account__td--category {
  i {
    opacity: 0.5;
    float: right;
    font-size: 1.2em;
    line-height: 30px;
  }
}

.account__add-button {
  padding: 3px 7px 4px;
  border: 0;
  background: none;
  color: $primary;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: 0;
  }

  .fa-sort-desc {
    padding-top: 1px;
    vertical-align: top;
  }
}

.account__tr--editing {
  position: relative;

  input {
    color: black;
  }
}

.account__editing-buttons {
  position: absolute;
  bottom: calc(-2rem - 6px);
  right: 4rem;
  background-color: $blue-hover;
  padding: 0 6px 6px;
  margin: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  white-space: nowrap;

  color: white;

  z-index: $z-editing-buttons;
}

.account__editing-button {
  position: relative;
  z-index: 1;
  list-style: none;
  line-height: 2rem;
  display: inline-block;
  padding: 0 1em;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-weight: 300;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.account__editing-button--save {
  background-color: $primary;

  &:hover {
    background-color: darken($primary, 10%);
  }
}

.account__category-label--empty {
  background-color: $danger;
  color: white;

  padding: 2px 5px;
  opacity: 1;

  display: inline !important;
}

.account__split-arrow {
  padding: 0 0.5em;

  cursor: pointer;
  transition: transform 250ms ease;

  &:hover {
    opacity: 1;
  }
}

.account__split-arrow--open {
  transform: rotate(90deg);
}

.account__transaction-editor {
  display: block;
  position: relative;
}

.account__add-split {
  color: white;
  border: 1px solid white;
  background: none;
  padding: 0 8px 0 5px;
  border-radius: 17px;
  line-height: 18px;
  vertical-align: top;
  margin-left: -36px;
  display: inline-block;
  margin-top: 4px;

  outline: none;

  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.account__remove-split {
  color: $danger;
  border: 1px solid $danger;
  background: none;
  width: 18px;
  border-radius: 10px;
  line-height: 16px;
  margin-top: 6px;
  margin-right: -4px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
  padding: 0;

  outline: none;

  opacity: 0.8;

  float: right;

  &:hover {
    opacity: 1;
  }
}

.account__amount-to-assign {
  position: absolute;
  right: 0;
}
