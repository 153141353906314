.mobile-type {
  flex: 1;

  display: flex;
}

.mobile-type__option {
  flex: 1;

  font-size: 1.5em;
  font-weight: 300;

  display: flex;
  align-items: center;
  justify-content: center;

  // For arrow overlapping amount
  position: relative;
  z-index: 2;
}

.mobile-type__option--income {
  color: $primary;
}

.mobile-type__option--expense {
  color: $negative-number;
}

.mobile-type__option--selected {
  color: white;

  &::after {
    $size: 10px;

    content: "";
    position: absolute;
    top: -$size;
    left: calc(50% - #{$size});

    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;

    border-bottom: $size solid;
  }

  &.mobile-type__option--income {
    background: $primary;

    &::after {
      border-bottom-color: $primary;
    }
  }

  &.mobile-type__option--expense {
    background: $negative-number;

    &::after {
      border-bottom-color: $negative-number;
    }
  }
}
