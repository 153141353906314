@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-hairline-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-HairlineItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-HairlineItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-HairlineItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-HairlineItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-HairlineItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 100;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-thin-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-ThinItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-ThinItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-ThinItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-ThinItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-ThinItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 200;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-light-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-LightItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-LightItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-LightItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-LightItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 300;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-regular-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-Italic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-Italic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-Italic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-Italic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-Italic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 400;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-medium-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-MediumItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-MediumItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-MediumItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-MediumItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-MediumItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 500;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-semibold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-SemiboldItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-SemiboldItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-SemiboldItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-SemiboldItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 600;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-bold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-BoldItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-BoldItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-BoldItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-BoldItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 700;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-heavy-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-HeavyItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-HeavyItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-HeavyItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-HeavyItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-HeavyItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 800;
//     text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: Lato;
  src: url("~lato-webfont/fonts/lato-black-webfont.woff") format("woff");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}

// @font-face {
//     font-family: Lato;
//     src: url('~lato-webfont/fonts/Lato-BlackItalic-webfont.eot');
//     src: url('~lato-webfont/fonts/Lato-BlackItalic-webfont.eot?#iefix') format('embedded-opentype'),
//          url('~lato-webfont/fonts/Lato-BlackItalic-webfont.woff2') format('woff2'),
//          url('~lato-webfont/fonts/Lato-BlackItalic-webfont.woff') format('woff'),
//          url('~lato-webfont/fonts/Lato-BlackItalic-webfont.ttf') format('truetype');
//     font-style: italic;
//     font-weight: 900;
//     text-rendering: optimizeLegibility;
// }
